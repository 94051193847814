import { FC, UIEvent, memo, useCallback } from 'react';
import { FileUtils } from '../../../utils/FileUtils';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';

type Prop = {
  id: string;
  name: string;
};

const AttachmentsRenderer: FC<{ data: Prop[] }> = (props) => {
  const onClick = useCallback((e: UIEvent<HTMLElement>, id: string, name: string) => {
    e.preventDefault();
    FileUtils.downloadFile({ id, name });
  }, []);

  if (!props.data.length) {
    return <>-</>;
  }

  return (
    <div className="max-h-16 overflow-y-auto overflow-x-clip">
      {props.data.map((x) => (
        <div key={x.id + x.name} {...mouseAndKeyboardCallbackProps((e) => onClick(e, x.id, x.name))} className="underline">
          {x.name}
        </div>
      ))}
    </div>
  );
};

export default memo(AttachmentsRenderer);
